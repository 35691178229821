h1,
h2,
h3,
p {
  margin-top: 0;
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 20px;
}

p {
  font-size: var(--text-paragraph-mobile);
}

@media screen and (min-width: 550px) {
  h1 {
    font-size: 22px;
  }
}

@media screen and (min-width: 750px) {
  h1 {
    font-size: 24px;
  }

  p {
    font-size: var(--text-paragraph-tablet);
  }
}

@media screen and (min-width: 1001px) {
  h1 {
    font-size: 25px;
  }
}
