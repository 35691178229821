.hero-holder {
  background-color: black;
}
.hero {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  margin: auto;
}

.polygon {
  width: 100%;
}

.hero-holder h1 {
  width: 90%;
  max-width: 750px;
  color: white;
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.arrow {
  width: 40px;
}

.sticky-whatsapp {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 50px;
  height: 50px;
}
