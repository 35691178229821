/*Colors*/

:root {
  --color-white-pale: #faf9f6;
  --color-purple: #b399fd;
  --color-blue: #49dbd9;
}

/*Space*/

:root {
  --space-small: 8px;
  --space-medium: 16px;
  --space-big: 24px;
}

/*Others*/

:root {
  --text-paragraph: 22px;
  --text-paragraph-mobile: 16px;
  --text-paragraph-tablet: 20px;
  --text-paragraph-x-small: 10px;
}
