/*Fonts*/
@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@700&family=Judson:wght@700&family=Libre+Baskerville:wght@700&family=Limelight&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  background-color: white;
}
