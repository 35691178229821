.brands-section {
  display: grid;
  grid-template-columns: repeat(1);
  justify-content: center;
  align-items: center;
}

@media (min-width: 500px) and (max-width: 700px) {
  .brands-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 701px) and (max-width: 1000px) {
  .brands-section {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1001px) and (max-width: 2000px) {
  .brands-section {
    grid-template-columns: repeat(4, 1fr);
  }
}
