.img-holder {
  position: relative;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  background-image: url("../../../public/assets/dubai.jpg");
  background-position: center;
  background-size: cover;
}

.blurred {
  width: 80%;
  height: 150px;
  background-color: rgba(1, 45, 60, 0.3);
  backdrop-filter: blur(30px);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  box-shadow: 10px 10px 10px rgba(10, 10, 10, 0.25);
  padding: var(--space-big);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Judson", serif;
  font-size: 16px;
  text-align: center;
  line-height: 25px;
}

.details {
  width: 80%;
  margin: auto;
  padding: var(--space-big);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Judson", serif;
  font-size: 16px;
  text-align: center;
  line-height: 25px;
}

@media (min-width: 450px) {
  .blurred {
    top: 70%;
  }
}

@media (min-width: 1100px) {
  .img-holder {
    background-position: 0% 45%;
  }
}
