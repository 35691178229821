.navbar {
  height: 120px;
  width: 100vw;
  padding: 16px 0px 0px;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navbar .logo-holder {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar .logo {
  width: 250px;
}

.links {
  width: 100vw;
  height: 45px;
  background-color: #c6c7c8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links a {
  width: 50%;
  font-size: var(--text-paragraph-mobile);
  text-align: center;
  font-weight: bold;
}

.links a {
  text-decoration: none;
  color: black;
}

.links a:hover {
  color: white;
}
