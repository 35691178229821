.contact-page {
  background-color: black;
  padding: var(--space-big);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--space-big);
}

.contact-form {
  width: 100%;
  max-width: 450px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: var(--space-medium);
}

.contact-form label,
.contact-form h2 {
  color: white;
  width: 100%;
  max-width: 450px;
}

.contact-form h2 {
  text-align: center;
}

.main-heading {
  margin: var(--space-big) 0px;
}

.contact-form .success-message {
  background-color: #47c757;
  border-radius: 10px;
  color: white;
  padding: var(--space-medium);
}

.contact-form .label {
  color: white;
  position: relative;
}

.contact-form .red {
  color: red;
  position: absolute;
  padding-left: 2px;
  top: -5px;
}

.contact-form input {
  background: black;
  border: var(--color-blue) 0.5px solid;
  height: 50px;
  width: 100%;
  padding: var(--space-small);
  max-width: 450px;
  border-radius: 10px;
  color-scheme: dark;
}

.contact-form textarea {
  background: black;
  border: var(--color-blue) 0.5px solid;
  height: 250px;
  width: 100%;
  padding: var(--space-small);
  max-width: 450px;
  border-radius: 10px;
  color-scheme: dark;
}

input[type="submit"] {
  background-color: #36abcd;
  color: black;
  cursor: pointer;
  border: var(--color-blue) 0.5px solid;
  color: white;
}

.address-section,
.hours-section,
.whatsapp-section {
  width: 100%;
  max-width: 450px;
  height: 200px;
  background-color: white;
  border-radius: 10px;
  font-family: "Judson", serif;
  line-height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.address-section h2,
.hours-section h2,
.whatsapp-section h2 {
  font-family: "Libre Baskerville", serif;
  margin-bottom: var(--space-medium);
}

.address-section p,
.hours-section p,
.whatsapp-section p {
  line-height: 30px;
}

.whatsapp {
  width: 70px;
  height: 70px;
}

.hours-section {
  height: 250px;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
