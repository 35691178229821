.brand-card {
  /* width: 280px; */
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-logo {
  width: 100px;
  /* max-width: 100%; */
  height: auto;
}

.hytera {
  width: 150px;
  /* max-width: 100%; */
  height: auto;
  padding-left: 20px;
}

.bao {
  width: 125px;
  max-width: 100%;
  height: auto;
}

.codan {
  width: 110px;
  max-width: 100%;
  height: auto;
}

.furuno {
  width: 120px;
  max-width: 100%;
  height: auto;
}

.icom {
  width: 80px;
  max-width: 100%;
  height: auto;
}

.tyt {
  width: 80px;
  max-width: 100%;
  height: auto;
}

.yaesu {
  width: 160px;
  max-width: 100%;
  height: auto;
}

.motorola {
  width: 50px;
  max-width: 100%;
  height: auto;
}

.sepura {
  width: 80px;
  max-width: 100%;
  height: auto;
}
.simrad {
  width: 100px;
  max-width: 100%;
  height: auto;
}
.garmin {
  width: 100px;
  max-width: 100%;
  height: auto;
}

.kenwood {
  width: 100px;
  max-width: 100%;
  height: auto;
}
